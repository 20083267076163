import request from "./request";

/**
 * 用户优惠券
 */

/**
 * 查询
 */
export const list = data => request.get("/uc/", { params: data });

/**
 * 删除
 */
export const del = id => request.post("/uc/del", { id });
