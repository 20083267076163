<template>
  <Layout>
    <div class="app-user-couponlist">
      <a-page-header title="发券记录" @back="() => this.$router.back()" />
      <div class="table-wrap">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
        >
          <span slot="avatarUrl" slot-scope="img">
            <img :src="img" class="avatar" />
          </span>
          <span slot="createdAt" slot-scope="time">{{
            time | formatDate
          }}</span>
          <span slot="action" slot-scope="_, record">
            <a @click="del(record.id)">删除</a>
          </span>
        </a-table>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

const UserCouponSvc = require("@/service/user_coupon");

const { getCouponTips } = require("@/utils/tool");

export default {
  name: "UserCouponList",

  data() {
    return {
      list: [],
      filters: {},
      pagination: {
        showTotal: total => `共 ${total} 条记录`
      },
      loading: false,

      selectedRowKeys: []
    };
  },

  computed: {
    columns() {
      const { filters } = this;

      return [
        {
          title: "头像",
          dataIndex: "avatarUrl",
          key: "avatarUrl",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "avatarUrl" }
        },
        {
          title: "昵称",
          dataIndex: "nickName",
          key: "nickName",
          width: 160,
          filteredValue: filters.nickName || null
        },
        {
          title: "备注",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
          filteredValue: filters.name || null
        },
        {
          title: "优惠券名称",
          dataIndex: "couponName",
          key: "couponName"
        },
        {
          title: "优惠详情",
          dataIndex: "type",
          key: "type",
          customRender: (_, item) => getCouponTips(item)
        },
        {
          title: "已领取",
          align: "center",
          dataIndex: "taken",
          key: "taken",
          width: 80,
          customRender: val => (val ? "是" : "否 ")
        },
        {
          title: "发券时间",
          dataIndex: "createdAt",
          key: "createdAt",
          width: 180,
          scopedSlots: { customRender: "createdAt" }
        },
        {
          title: "操作",
          key: "action",
          width: 80,
          scopedSlots: { customRender: "action" }
        }
      ];
    }
  },

  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    async del(id) {
      this.$confirm({
        title: "操作提示",
        content: "确认删除优惠券吗？",

        onOk: () => {
          this._delete(id);
        },
        onCancel() {}
      });
    },

    async _delete(id) {
      try {
        await UserCouponSvc.del(id);

        await this.fetch();
      } catch (err) {
        console.error(err);
        this.$message.error("删除失败，错误：" + err.message);
      }
    },

    async handleSearch(e) {
      e.preventDefault();

      await this.fetch(this.filters);
    },

    async handleTableChange(pagination, filters) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.filters = filters;

      const { pageSize, current: page } = pagination;
      await this.fetch({
        pageSize,
        page,
        ...filters
      });
    },

    async fetch(params = {}) {
      this.loading = true;

      try {
        const data = await UserCouponSvc.list({
          page: 1,
          pageSize: 10,
          ...params
        });

        const pagination = {
          ...this.pagination,
          total: data.cnt,
          current: data.page
        };
        this.list = data.res;
        this.pagination = pagination;

        this.loading = false;
      } catch (err) {
        this.loading = false;

        console.error(err);
        this.$message.error("加载数据出错，错误：" + err.message);
      }
    }
  },

  async mounted() {
    await this.fetch();
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-user-couponlist {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .table-wrap {
    background: #fff;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid #fff;
    }

    .ant-table-pagination.ant-pagination {
      float: none;
      text-align: center;
    }
  }
}
</style>
